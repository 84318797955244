export const About = (props) => {
    return (
        <div id="about">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        {" "}
                        <img src="img/room2.jpg" className="img-responsive" width={"10%"} alt="" />{" "}
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="about-text">
                            <h2>
                                Why
                                <a href="https://youtu.be/QVVFt0hx1Eg" className="about h2 link">
                                    (Click to see video)
                                </a>
                            </h2>
                            <p>
                                {props.data ? props.data.paragraph : "loading..."}
                                <li>{props.data ? props.data.paragraphA : "loading..."}</li>
                                <li>{props.data ? props.data.paragraphC : "loading..."}</li>
                                <li>{props.data ? props.data.paragraphD : "loading..."}</li>
                                <li>{props.data ? props.data.paragraphE : "loading..."}</li>
                            </p>
                            <p>
                                Well - imagine no more. A single, simple, super high-end system <u>for mass market products</u>. Connect several devices on the
                                beach, bring it with you to a pick-nick, easily hide speakers in the walls or make use and improve that unique speaker that fits
                                so well in a particular spot.
                            </p>
                            <p>It's About Time&trade;</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
