//import { useState } from "react";
//import emailjs from "emailjs-com";

export const Contact = (props) => {
    return (
        <div>
            <div id="contact">
                <div className="container">
                    <div className="col-md-3 col-md-offset-1 contact-info">
                        <div className="contact-item">
                            <h3>Contact Info</h3>
                            <p>
                                <span>
                                    <i className="fa fa-map-marker"></i> Address
                                </span>
                                {props.data ? props.data.address : "loading"}
                            </p>
                        </div>
                        <div className="contact-item">
                            <p>
                                <span>
                                    <i className="fa fa-envelope-o"></i> Email
                                </span>{" "}
                                {props.data ? props.data.email : "loading"}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row"></div>
                    </div>
                </div>
            </div>
            <div id="footer">
                <div className="container text-center">
                    <p>&copy; 2021,2022,2023 AbtAudio AB</p>
                </div>
            </div>
        </div>
    );
};
