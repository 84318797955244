export const Gallery = (props) => {
    return (
        <div id="portfolio" className="text-center">
            <div className="container">
                <div className="section-title">
                    <h2>Solution</h2>
                    <p>Short Summary</p>
                </div>
                <div className="row">
                    <div className="portfolio-items">
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="portfolio-item">
                                <div className="hover-bg">
                                    {" "}
                                    <a href="img/portfolio/aboutTimeAudioPublicShort_page1.jpg" title="Page1" data-lightbox-gallery="gallery1">
                                        <div className="hover-text">
                                            <h4>Summary</h4>
                                        </div>
                                        <img
                                            src="img/portfolio/aboutTimeAudioPublicShort_page1_small.jpg"
                                            className="img-responsive"
                                            alt="About Time Audio, Short Presentation"
                                        />{" "}
                                    </a>{" "}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="portfolio-item">
                                <div className="hover-bg">
                                    {" "}
                                    <a href="img/portfolio/aboutTimeAudioPublicShort_page2.jpg" title="Page2" data-lightbox-gallery="gallery1">
                                        <div className="hover-text">
                                            <h4>User Benefits</h4>
                                        </div>
                                        <img
                                            src="img/portfolio/aboutTimeAudioPublicShort_page2_small.jpg"
                                            className="img-responsive"
                                            alt="About Time Audio, Short Presentation"
                                        />{" "}
                                    </a>{" "}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="portfolio-item">
                                <div className="hover-bg">
                                    {" "}
                                    <a href="img/portfolio/aboutTimeAudioPublicShort_page3.jpg" title="Page3" data-lightbox-gallery="gallery1">
                                        <div className="hover-text">
                                            <h4>Vendor Benefits</h4>
                                        </div>
                                        <img
                                            src="img/portfolio/aboutTimeAudioPublicShort_page3_small.jpg"
                                            className="img-responsive"
                                            alt="About Time Audio, Short Presentation"
                                        />{" "}
                                    </a>{" "}
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="portfolio-item">
                                <div className="hover-bg">
                                    {" "}
                                    <a href="img/portfolio/aboutTimeAudioPublicShort_page4.jpg" title="Page4" data-lightbox-gallery="gallery1">
                                        <div className="hover-text">
                                            <h4>System Overview</h4>
                                        </div>
                                        <img
                                            src="img/portfolio/aboutTimeAudioPublicShort_page4_small.jpg"
                                            className="img-responsive"
                                            alt="About Time Audio, Short Presentation"
                                        />{" "}
                                    </a>{" "}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="portfolio-item">
                                <div className="hover-bg">
                                    {" "}
                                    <a href="img/portfolio/aboutTimeAudioPublicShort_page5.jpg" title="Page5" data-lightbox-gallery="gallery1">
                                        <div className="hover-text">
                                            <h4>Transmitter SW</h4>
                                        </div>
                                        <img
                                            src="img/portfolio/aboutTimeAudioPublicShort_page5_small.jpg"
                                            className="img-responsive"
                                            alt="About Time Audio, Short Presentation"
                                        />{" "}
                                    </a>{" "}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="portfolio-item">
                                <div className="hover-bg">
                                    {" "}
                                    <a href="img/portfolio/aboutTimeAudioPublicShort_page6.jpg" title="Page6" data-lightbox-gallery="gallery1">
                                        <div className="hover-text">
                                            <h4>Speaker SW</h4>
                                        </div>
                                        <img
                                            src="img/portfolio/aboutTimeAudioPublicShort_page6_small.jpg"
                                            className="img-responsive"
                                            alt="About Time Audio, Short Presentation"
                                        />{" "}
                                    </a>{" "}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="portfolio-item">
                                <div className="hover-bg">
                                    {" "}
                                    <a href="img/portfolio/aboutTimeAudioPublicShort_page7.jpg" title="Page7" data-lightbox-gallery="gallery1">
                                        <div className="hover-text">
                                            <h4>Fast and accurate synchronization</h4>
                                        </div>
                                        <img
                                            src="img/portfolio/aboutTimeAudioPublicShort_page7_small.jpg"
                                            className="img-responsive"
                                            alt="About Time Audio, Short Presentation"
                                        />{" "}
                                    </a>{" "}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="portfolio-item">
                                <div className="hover-bg">
                                    {" "}
                                    <a href="img/portfolio/aboutTimeAudioPublicShort_page8.jpg" title="Page8" data-lightbox-gallery="gallery1">
                                        <div className="hover-text">
                                            <h4>Inaudible clock adjustment</h4>
                                        </div>
                                        <img
                                            src="img/portfolio/aboutTimeAudioPublicShort_page8_small.jpg"
                                            className="img-responsive"
                                            alt="About Time Audio, Short Presentation"
                                        />{" "}
                                    </a>{" "}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4">
                            <div className="portfolio-item">
                                <div className="hover-bg">
                                    {" "}
                                    <a href="img/portfolio/aboutTimeAudioPublicShort_page9.jpg" title="Page9" data-lightbox-gallery="gallery1">
                                        <div className="hover-text">
                                            <h4>Next step</h4>
                                        </div>
                                        <img
                                            src="img/portfolio/aboutTimeAudioPublicShort_page9_small.jpg"
                                            className="img-responsive"
                                            alt="About Time Audio, Short Presentation"
                                        />{" "}
                                    </a>{" "}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
